<template>
    <v-container fluid>
        <base-material-card icon="fas fa-file-invoice" title="CxC" class="px-5 py-3">
            <v-row>
                <v-col cols="4">
                    <v-menu v-model="menu_fecha_inicio" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_inicial" label="Fecha inicial" readonly v-on="on" :error-messages="errors.fecha_inicial"></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_inicial" @input="menu_fecha_inicio = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-menu v-model="menu_fecha_final" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_final" label="Fecha final" readonly v-on="on" :error-messages="errors.fecha_final"></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_final" @input="menu_fecha_final = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-select v-model="pagination.cliente_id" @input="loadSucursales(); load()" :items="clientes" label="Cliente" item-value="id" item-text="razon_social" :error-messages="errors.categoria_id" clearable></v-select>
                </v-col>
                <v-col cols="4">
                    <v-select v-model="pagination.sucursal_id" @input="load" :items="sucursales" label="Sucursales" item-value="id" item-text="nombre" :error-messages="errors.categoria_id" clearable multiple></v-select>
                </v-col>
                <v-col cols="4">
                    <v-select v-model="pagination.vendedor_id" @input="load" :items="vendedores" label="Vendedor" item-value="id" item-text="razon_social" :error-messages="errors.categoria_id" clearable></v-select>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="downloadExcel">Descargar</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="ventas" :loading="loading" hide-default-footer disable-pagination>
                        <template v-slot:item.id="{item}">
                            {{item.id.toString().padStart(5, "0")}}
                        </template>
                        <template v-slot:item.dias="{item}">
                            {{`${item.dias_transcurridos} / ${item.cliente.credito_dias}`}}
                        </template>
                        <template v-slot:item.total="{item}">
                            {{formatoMoneda(item.total)}}
                        </template>
                        <template v-slot:body.append>
                            <tr>
                                <td colspan="7"></td>
                                <td class="font-weight-bold">{{formatoMoneda(total)}}</td>
                            </tr>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">More info about {{ item.name }}</td>
                          </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            errors: {},
            clientes: [],
            sucursales: [],
            vendedores: [],
            ventas: [],
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Fecha', value: 'fecha_venta' },
                { text: 'Límite', value: 'fecha_limite' },
                { text: 'Días', value: 'dias' },
                { text: 'Cliente', value: 'cliente.razon_social' },
                { text: 'Sucursal', value: 'sucursal.nombre' },
                { text: 'Vendedor', value: 'vendedor.razon_social' },
                { text: 'Importe', value: 'total' },
            ],
            pagination: {
                fecha_inicial: null,
                fecha_final: null
            },
            menu_fecha_inicio: false,
            menu_fecha_final: false,
            loading: false
        }),
        mounted() {
            this.setInitialDates()
            this.load()
            this.loadClientes()
            this.loadVendedores()
        },
        computed: {
            total: function () {
                if (this.ventas.length) {
                    return this.ventas.map(venta => venta.total).reduce((a, b) => { return a + b })
                }
                return 0
            }
        },
        methods: {
            load() {
                this.loading = true
                this.ventas = []
                this.$http.get('/reportes/ventas/cxc', {
                    params: this.pagination
                }).then(response => {
                    this.ventas = response.data
                    this.pagination.lastPage = response.data.last_page
                    this.pagination.page = response.data.current_page
                }).finally(() => {
                    this.loading = false
                })
            },
            loadClientes() {
                this.$http.get('/clientes/all').then(response => {
                    this.clientes = response.data
                })
            },
            loadSucursales() {
                this.pagination.sucursal_id = []
                this.sucursales = []
                this.$http.get(`/clientes/${this.pagination.cliente_id}/sucursales`).then(response => {
                    this.sucursales = response.data
                })
            },
            loadVendedores() {
                this.$http.get('/vendedores/all').then(response => {
                    this.vendedores = response.data
                })
            },
            loadVenta(venta) {
            },
            setInitialDates() {
                this.pagination.fecha_inicial = moment().startOf('day').format('YYYY-MM-DD')
                this.pagination.fecha_final = moment().startOf('day').format('YYYY-MM-DD')
            },
            downloadExcel() {
                window.location.assign(`/reportes/ventas/cxc/excel?${jQuery.param(this.pagination)}`)
            }
        }
    }
</script>